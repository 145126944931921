<template>
    <cluji-card>
        <template #title>
            <!-- Editor toolbar -->
            <b-button v-if="!isEditing && canWrite" pill variant="info" size="sm" class="ml-2"
                      v-b-tooltip.hover="'Éditer'" @click="startEdition">
                <font-awesome-icon icon="pen-nib"/>
            </b-button>
            <span v-if="isEditing">
                <b-button v-if="isEditing" pill variant="danger" size="sm" class="ml-2"
                          v-b-tooltip.hover="'Annuler'" @click="cancelEdition">
                    <font-awesome-icon icon="times"/>
                </b-button>
                <b-button v-if="isEditing" pill variant="success" size="sm" class="ml-2"
                          v-b-tooltip.hover="'Enregistrer'" @click="saveArticle">
                    <font-awesome-icon icon="save"/>
                </b-button>

                <brouillon-toolbar-wiki :article-id="article.id" :corps="article.corps" @loadCorps="loadCorps"/>
            </span>
            <!-- / Toolbar -->

            <small class="ml-5" v-else>
                <font-awesome-icon icon="chevron-left" class="mr-1"/>
                <router-link :to="linkToWikiHome">Retour à l'accueil du wiki</router-link>
            </small>
        </template>

        <div class="text-center">
            <h1 class="text-center">{{ article.nom }}</h1>
            <p class="text-muted">
                Créé par
                <user-name :user="article.createur"/>
                le {{ formatDate(article.dateCreation, 'Do MMMM YYYY [à] H[h]mm') }}
                <span v-if="article.modifications.length">
                    <br/>
                    Modifié en dernier par <user-name :user="article.modifications[0].utilisateur"/>
                    le {{ formatDate(article.modifications[0].date, 'Do MMMM YYYY [à] H[h]mm') }}
                </span>
            </p>
            <b-row>
                <b-col md="8" offset-md="2">
                    <hr class="mb-3 mt-2"/>
                </b-col>
            </b-row>
        </div>

        <balloon-editor v-if="isEditing" v-model="article.corps" :image-upload-url="imageUploadUrl"/>
        <b-row v-else>
            <b-col xl="10" offset-xl="1" cols="12">
                <div class="ck-content" v-html="sanitize(article.corps)"></div>
            </b-col>
        </b-row>
    </cluji-card>
</template>

<script>
    import {sanitize} from 'dompurify';

    import {apiPath}    from '@/util/http';
    import alert        from '@/util/alert';
    import wiki         from '@/util/wiki';
    import {isGranted}  from '@/util/auth';
    import {formatDate} from '@/util/date';

    const BrouillonToolbarWiki = () => import('@/components/wiki/BrouillonToolbarWiki');
    const UserName             = () => import('@/components/UserName');
    const BalloonEditor        = () => import('@/components/BalloonEditor');
    const ClujiCard            = () => import('@/components/ClujiCard');

    export default {
        name: "WikiArticle",
        components: {BrouillonToolbarWiki, UserName, BalloonEditor, ClujiCard},
        data: () => ({
            article: {id: null, nom: null, corps: null, modifications: []},
            isEditing: false,
            corpsBackup: null
        }),
        computed: {
            imageUploadUrl() {
                return apiPath('wiki_image_upload', {article: this.article.id});
            },
            linkToWikiHome() {
                return wiki.linkToWikiHome(this.$route);
            },
            canWrite() {
                return isGranted(this.article.editable);
            }
        },
        methods: {
            sanitize,
            formatDate,
            loadData() {
                alert.loading();
                return this.axios.get(apiPath('wiki_article_full', {article: this.$route.params.articleId}))
                    .then(response => this.article = wiki.momentDatesArticle(response.data))
                    .catch(() => this.$toaster.error("Impossible de récupérer le contenu de l'article"))
                    .finally(alert.stopLoading);
            },
            endEdition() {
                this.isEditing        = false;
                window.onbeforeunload = function () {
                    return undefined;
                };
            },
            doCancelEdition() {
                this.article.corps = this.corpsBackup;
                this.endEdition();
            },
            cancelEdition() {
                if (this.article.corps === this.corpsBackup) {
                    this.doCancelEdition();

                    return;
                }

                alert.confirm(
                    "Quitter l'éditeur sans enregistrer vos modifications ?",
                    () => this.doCancelEdition()
                );
            },
            startEdition() {
                this.corpsBackup      = this.article.corps;
                this.isEditing        = true;
                window.onbeforeunload = function () {
                    return "Quitter la page sans enregistrer l'article ?";
                }
            },
            saveArticle() {
                alert.loading();
                this.axios
                    .post(apiPath('wiki_article_save_content', {article: this.article.id}), {
                        corps: this.article.corps
                    })
                    .then(() => this.loadData())
                    .then(() => this.endEdition())
                    .catch(() => this.$toaster.error("Impossible d'enregistrer l'article"))
                    .finally(alert.stopLoading);
            },
            loadCorps(corps) {
                this.article.corps = corps;

                this.endEdition(); // Restart the editor
                this.$nextTick(() => this.startEdition());

                this.$toaster.success('Brouillon chargé');
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
